import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import Record from '../../../../img/Icons/White Icons/TitanTV Icon_Record_White.png'
import Burn from '../../../../img/Icons/White Icons/TitanTV Icon_Burn to DVD_White.png'
import WatchNow from '../../../../img/Icons/White Icons/TitanTV Icon_Watch Now_White.png'
import ReminderIconWhite from '../../../../img/Icons/White Icons/TitanTV Icon_Reminder_White.png'
import ReminderIconYellow from '../../../../img/Icons/Yellow Icons/TitanTV Icon_Reminder_Yellow.png'
import Calendar from '../../../../img/Icons/White Icons/TitanTV Icon_Calendar_White.png'
import WhiteHeart from '../../../../img/Icons/White Icons/TitanTV Icon_White_Heart.svg'
import RedHeart from '../../../../img/Icons/Red Icons/TitanTV Icon_Heart_Red.svg'
import { StyledEventControls, EventControl } from './EventDetailsStyles'
import { Event } from '../../../../interfaces/schedule'
import { selectIsAnonymous, selectUserId } from '../../../UserSettingsPages/SettingsState/userSlice'
import {
	deleteFavorite, deleteReminders, fetchFavorites, fetchReminders, fetchUpcomingFavorites, putFavorite, putReminder, selectFavorites,
	selectReminders, selectRemindersStatus, setIsFav, selectIsReminder, setIsReminder, selectFavoritesStatus, fetchUpcomingReminders,
	selectIsFav, selectFavoriteDeleteStatus, setFavoriteDeleteId
} from '../../../UserSettingsPages/SettingsState/favoritesRemidersSlice'
import { selectLineups } from '../../../UserSettingsPages/SettingsState/lineupsSlice';
import { putPVRiCalLog, selectEventDetailsStatus } from '../../detailsSlice'
import { DataState, ScreenSize } from '../../../../interfaces/enums'
import { selectGridLineupID, selectMainSchedule } from '../../mainGridSlice'
import { getScreenSizeType, icsBuilder, leadingZeroBuilder, tvpiBuilder } from '../../../Global/GobalFunctions'
import {
	selectEnableBurnToDVD, selectEnableRecord, selectEnableWatch, setDisplayWatch, setDisplayRecord, setDisplayBurn, setDisplayICal,
	selectDisplayWatch, selectDisplayRecord, selectDisplayBurn, selectDisplayICal
} from '../../../UserSettingsPages/SettingsState/gridSettingsSlice';
import { UserLineup } from '../../../../interfaces/settings';

const EventControls = (props: any) => {
	let reminderId = 0;

	const event: Event = props.event
	const channelIndex = props.channelIndex
	const channel = props.channel
	const schedule = useAppSelector(selectMainSchedule);
	const userId = useAppSelector(selectUserId);
	const lineupId = useAppSelector(selectGridLineupID);
	const lineups = useAppSelector(selectLineups);
	const dispatch = useAppDispatch();
	const favs = useAppSelector(selectFavorites);
	const favsStatus = useAppSelector(selectFavoritesStatus);
	const isAnon = useAppSelector(selectIsAnonymous);
	let favId = 0;
	const isFav = useAppSelector(selectIsFav);
	const favDeleteStatus = useAppSelector(selectFavoriteDeleteStatus);
	const detailsStatus = useAppSelector(selectEventDetailsStatus);
	const reminders = useAppSelector(selectReminders);
	const remindersStatus = useAppSelector(selectRemindersStatus);
	const isReminder = useAppSelector(selectIsReminder);
	const enableWatch = useAppSelector(selectEnableWatch);
	const enableRecord = useAppSelector(selectEnableRecord);
	const enableBurn = useAppSelector(selectEnableBurnToDVD);
	const displayWatch = useAppSelector(selectDisplayWatch);
	const displayRecord = useAppSelector(selectDisplayRecord);
	const displayBurn = useAppSelector(selectDisplayBurn);
	const displayICal = useAppSelector(selectDisplayICal);
	let isMobile = false;

	const handleFavoriteClick = () => {
		if ((isAnon && favs.favorites.length > 199) || favs.favorites.length > 499) {
			alert('Too many favorites, Delete some to add more');
			return;
		}
		if (isFav) {
			dispatch(deleteFavorite({ userId, favId }));
			dispatch(setFavoriteDeleteId(favId))
		} else {
			dispatch(putFavorite({ userId: userId, fav: event.title }));
		}
		const now = new Date();
		const startTime = `${now.getFullYear()}${leadingZeroBuilder(now.getMonth() + 1)}${leadingZeroBuilder(now.getDate())}${leadingZeroBuilder(now.getHours())}00`
		dispatch(fetchUpcomingFavorites({ userId, lineupId, startTime, duration: 1440 }));
	}

	const handleReminderClick = () => {
		if (isReminder) {
			dispatch(deleteReminders({ userId, reminderId: reminderId }));
		} else {
			const reminderData = {
				lineupId: lineupId,
				lineupCacheId: schedule.lineupCacheId,
				channelIndex: channelIndex,
				eventId: event.eventId
			}
			dispatch(putReminder({ userId, putReminder: reminderData }));
		}
		dispatch(fetchReminders({ userId, lineupId, numberOfDays: 14 }));
		dispatch(fetchUpcomingReminders({ userId, lineupId }));
	}

	/*
		B - broadcast
			Digital Cable
		[C - cable ready TV
		D - digital cable]

		O - live streaming
			Analog Cable
		[A - analog cable
		R - cable rebuild lineup]

		S - satellite
	*/

	const getLineup = (): UserLineup => {
		let lineup = {} as UserLineup
		for (let i = 0; i < lineups.lineups.length; i++) {
			if (lineups.lineups[i].lineupId === lineupId) {
				lineup = lineups.lineups[i]
				break;
			}
		}
		return lineup;
	}

	const handlePRVLog = async (blob: Blob) => {
		const text = await new Response(blob).text();
		dispatch(putPVRiCalLog({ userId, doc: text }));
	}

	const handleRecord = () => {
		let blob = tvpiBuilder({ event: event, channel: channel, lineup: getLineup(), burn: false, watch: false, record: true })
		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.download = 'record-program.tvpi';
		link.href = url;
		link.click();
		handlePRVLog(blob);
	}

	const handleBurnToDisc = () => {
		let blob = tvpiBuilder({ event: event, channel: channel, lineup: getLineup(), burn: true, watch: false, record: false })
		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.download = 'burn-program.tvpi';
		link.href = url;
		link.click();
		handlePRVLog(blob);
	}

	const handleWatchNow = () => {
		let blob = tvpiBuilder({ event: event, channel: channel, lineup: getLineup(), burn: false, watch: true, record: false })
		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.download = 'watch-program.tvvi';
		link.href = url;
		link.click();
		handlePRVLog(blob);
	}

	const handleAddToCalander = () => {
		let blob = icsBuilder({ event: event, channel: channel })
		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.download = 'reminder.ics';
		link.href = url;
		link.click();
		handlePRVLog(blob);
	}

	useEffect(() => {
		if (favsStatus !== DataState.SUCCEEDED) {
			dispatch(fetchFavorites(userId));
		}

		dispatch(fetchReminders({ userId, lineupId, numberOfDays: 14 }));
		dispatch(setDisplayBurn(enableBurn))
		dispatch(setDisplayRecord(enableRecord));
		dispatch(setDisplayWatch(enableWatch));

		if (getScreenSizeType() === ScreenSize.MOBILE) {
			isMobile = true;
			dispatch(setDisplayBurn(false));
			dispatch(setDisplayWatch(false));
			dispatch(setDisplayRecord(false));
		}

		return (() => {
			dispatch(setIsReminder(false));
			dispatch(setIsFav(false));
		})
		// eslint-disable-next-line
	}, []);

	if (favsStatus === DataState.SUCCEEDED && detailsStatus === DataState.SUCCEEDED || favDeleteStatus === DataState.SUCCEEDED) {
		let favArray = favs.favorites
		for (let i = 0; i < favArray.length; i++) {
			if (favArray[i].title === event.title) {
				favId = favArray[i].favoriteId;
				dispatch(setIsFav(true));
				break;
			}
		}
	}

	if (remindersStatus === DataState.SUCCEEDED && detailsStatus === DataState.SUCCEEDED) {
		const remArray = reminders.reminders
		for (let i = 0; i < remArray.length; i++) {
			if (event.eventId === remArray[i].eventId) {
				reminderId = remArray[i].reminderId
				dispatch(setIsReminder(true));
				break;
			}
		}
	}

	let now = new Date();
	let start = new Date(event.startTime);
	let end = new Date(event.endTime);

	if (now > start) {
		dispatch(setDisplayRecord(false));
		dispatch(setDisplayBurn(false));
		dispatch(setDisplayICal(false));
	} else {
		dispatch(setDisplayRecord(enableRecord));
		dispatch(setDisplayBurn(enableBurn));
		dispatch(setDisplayICal(true));
	}

	if (now > start && now < end) {
		dispatch(setDisplayWatch(enableWatch));
	} else {
		dispatch(setDisplayWatch(false));
	}

	return (
		<StyledEventControls>
			{(detailsStatus === DataState.SUCCEEDED && remindersStatus === DataState.SUCCEEDED && favsStatus === DataState.SUCCEEDED && remindersStatus === DataState.SUCCEEDED) ?
				<>
					{displayWatch ? <EventControl title='Watch Now' src={WatchNow} onClick={() => handleWatchNow()} /> : ''}
					{displayRecord ? <EventControl title='Record Event' src={Record} onClick={() => handleRecord()} /> : ''}
					{displayBurn ? <EventControl title='Burn Event to Disc' src={Burn} onClick={() => handleBurnToDisc()} /> : ''}
					{displayICal ? <EventControl title='Add Event to Calendar' src={Calendar} onClick={() => handleAddToCalander()} /> : ''}
					<EventControl title='Set Reminder' src={isReminder ? ReminderIconYellow : ReminderIconWhite} onClick={() => handleReminderClick()} />
					<EventControl title='Set Program as Favorite' src={isFav ? RedHeart : WhiteHeart} onClick={(() => handleFavoriteClick())} />
				</>
				: ''}
		</StyledEventControls>
	)
}

export default EventControls