import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
	SettingsContainer, Understanding, Favorite24Hours, FavoriteHeader, FavoriteUpcomingContainer,
	FavoriteUpcomingNone, StyledUpcomingFavoriteBox, UpcomingTimeDate, UpcomingChannelNumber, UpcomingEvent,
	UpcomingTitle, UpcomingEpisodeTitle, UpcomingInnerBox, FavoriteContainer, ReminderDeleteContainer,
	DataDeleted, SettingsHeader, BecomeMemberContainer, DataError
} from '../components/SettingsStyles';
import {
	deleteReminders, fetchReminders, selectReminders, selectRemindersStatus, selectReminderDeleteStatus, setReminderDeleteId,
	setReminderDeleteStatus
} from '../SettingsState/favoritesRemidersSlice';
import TabBar from '../components/TabBar';
import { selectUserId } from '../SettingsState/userSlice';
import { CreateUUID, GetDefaultUser, eventFlyOverText, leadingZeroBuilder, shouldDisplayNGChannel } from '../../Global/GobalFunctions';
import { Reminder } from '../../../interfaces/reminders';
import { fetchLineupChannels, selectGridLineupID, selectLineupChannels, selectMainGridChannelStatus } from '../../GridPages/mainGridSlice';
import { Channel } from '../../../interfaces/channels';
import { selectColors } from '../../GridPages/gridColorsSlice';
import { fetchEventDetails, setDetailsChannel } from '../../GridPages/detailsSlice';
import { setModalContent, setModalOpen } from '../../Modal/modalSlice';
import { DataState, ModalState } from '../../../interfaces/enums';
import { ClickableChannelCard } from '../../Global/GobalComponents';
import GridLoading from '../../GridPages/components/GridLoading';
import GridFailed from '../../GridPages/components/GridFailed';
import { ModalIconSM } from '../../Modal/ModalStyles';
import Close from '../../../img/Icons/Red Icons/TitanTV Icon_Close_Red.png';
import BecomeMemberButton from '../components/BecomeMemberButton';
import { handleErrorMessage } from '../../Global/ErrorMessage';
import EventAttributes from '../../GridPages/components/EventAttributes';
import BackToGridButton from '../components/BackToGridButton';
import { selectHideNGChannels } from '../SettingsState/gridSettingsSlice';

const Reminders = () => {
	const dispatch = useAppDispatch();
	const reminders = useAppSelector(selectReminders);
	const remindersStatus = useAppSelector(selectRemindersStatus);
	const userId = useAppSelector(selectUserId);
	const lineupId = useAppSelector(selectGridLineupID);
	const channelStatus = useAppSelector(selectMainGridChannelStatus);
	const deleteStatus = useAppSelector(selectReminderDeleteStatus);

	useEffect(() => {
		window.scrollTo(0, 0);
		dispatch(fetchReminders({ userId, lineupId, numberOfDays: 14 }));
		dispatch(fetchLineupChannels({ userId, lineupId }));
		dispatch(setReminderDeleteStatus(DataState.IDLE));
	}, [])

	return (
		<>
			<TabBar />
			<BackToGridButton />
			<SettingsContainer>
				{userId === GetDefaultUser() ?
					<BecomeMemberContainer>
						<SettingsHeader>You must Create a user to have reminders</SettingsHeader>
						<BecomeMemberButton />
					</BecomeMemberContainer> :
					<FavoriteContainer>
						<Favorite24Hours>
							<FavoriteHeader>
								Program Reminders
							</FavoriteHeader>
							{deleteStatus === DataState.SUCCEEDED ? <DataDeleted>Reminder Deleted</DataDeleted> : ''}
							{(remindersStatus === DataState.LOADING || channelStatus === DataState.LOADING) ? <GridLoading /> :
								(remindersStatus === DataState.FAILED || channelStatus === DataState.FAILED) ? <GridFailed errorCode={reminders.errorCode} errorMessage={reminders.errorMessage} /> :
									reminders.errorCode !== 0 ? <DataError>{handleErrorMessage(reminders.errorCode)}</DataError> :
										(reminders.reminders === undefined) ? '' :
											(reminders.reminders.length < 1) ? <FavoriteUpcomingNone>No Reminders</FavoriteUpcomingNone> :
												<UpcomingInnerBox>
													<FavoriteUpcomingContainer>
														{reminders.reminders.map((reminder) =>
															<RemindersBox reminder={reminder} key={`Rem${CreateUUID()}`} />
														)}
													</FavoriteUpcomingContainer>
												</UpcomingInnerBox>
							}
						</Favorite24Hours>
					</FavoriteContainer>
				}
				<Understanding>
					<span>
						<b>About This Page:</b>
						<p>This page displays a list of upcoming programs and movies for which you have set a Reminder.</p>
						<p>A Reminder is applied to a program or movie by clicking on the Reminder icon in its Details page.</p>
						<p>Reminders for programs that air within the next 24 hours will appear in the ticker tape area on the header above the grid.</p>
						<p>Once a program has aired, its Reminder is removed from this page.</p>
						<p>To cancel a Reminder, click on the red X on the right side of its box in this list.</p>
						<p>Click the TitanTV logo to return to the schedule page.</p>
					</span>
				</Understanding>
			</SettingsContainer>
		</>
	)
}

export default Reminders

const RemindersBox = (props: any) => {
	const reminder: Reminder = props.reminder;
	const channels = useAppSelector(selectLineupChannels);
	const dispatch = useAppDispatch();
	const userId = useAppSelector(selectUserId);
	const lineupId = useAppSelector(selectGridLineupID);
	let startTime = new Date(reminder.startTime);
	let hour = startTime.getHours();
	let min = leadingZeroBuilder(startTime.getMinutes());
	const { GridColors } = useAppSelector(selectColors);
	type key = keyof typeof GridColors;
	let genre = reminder.displayGenre;
	let AMPM = 'AM';
	let dateString = `${leadingZeroBuilder(startTime.getMonth() + 1)}/${leadingZeroBuilder(startTime.getDate())}`;
	let channel = {} as Channel
	const hideNG = useAppSelector(selectHideNGChannels);

	if (genre === 'Game/Quiz') { genre = 'Game_Quiz' }

	const handleEventCardClick = (userId: string, lineupId: string, eventId: number) => {
		dispatch(setDetailsChannel(channel))
		dispatch(fetchEventDetails({ userId: userId, lineupId: lineupId, channelIndex: channel.channelIndex, eventId }));
		dispatch(setModalContent(ModalState.EVENTDETAILS));
		dispatch(setModalOpen());
	}

	const newRepeat = (str: string): string => {
		if (str === 'N') { return 'New' }
		if (str === 'R') { return 'Repeat' }
		return ''
	}

	const displayDetails = (): boolean => {
		let display = false;
		if (reminder.tvRating !== '') { display = true }
		if (reminder.mpaaRating !== '') { display = true }
		if (reminder.isHD) { display = true }
		return display;
	}

	const handleDelete = () => {
		dispatch(setReminderDeleteId(reminder.reminderId));
		dispatch(deleteReminders({ userId, reminderId: reminder.reminderId }));
	}

	if (startTime.getHours() > 11) {
		AMPM = 'PM';
	}
	if (hour === 0) { hour = 12; }
	if (hour > 12) { hour = hour - 12; }

	for (let i = 0; i < channels.channels.length; i++) {
		if (channels.channels[i].channelId === reminder.channelId) {
			channel = channels.channels[i];
		}
	}

	return (
		<StyledUpcomingFavoriteBox shouldDisplay={shouldDisplayNGChannel(channel.isNextGen, hideNG)}>
			<UpcomingTimeDate>
				<div>{dateString}</div>
				<div>{`${hour}:${min} ${AMPM}`}</div>
			</UpcomingTimeDate>
			<ClickableChannelCard channel={channel} />
			<UpcomingChannelNumber>
				{reminder.majorChannel > 0 ? reminder.majorChannel + '.' + reminder.minorChannel : reminder.rfChannel}
			</UpcomingChannelNumber>
			<UpcomingEvent color={GridColors[genre as key]} onClick={() => handleEventCardClick(userId, lineupId, reminder.eventId)} title={eventFlyOverText(reminder.title, reminder.episodeTitle, true)}>
				<UpcomingTitle>{reminder.title}</UpcomingTitle>
				{reminder.title !== reminder.episodeTitle ?
					<UpcomingEpisodeTitle>{reminder.episodeTitle}</UpcomingEpisodeTitle>
					: ''
				}
				{displayDetails() ? <EventAttributes Event={reminder} showAirDate={false} /> : ''}

			</UpcomingEvent>
			<ReminderDeleteContainer color={GridColors[genre as key]}>
				<ModalIconSM title={`Delete reminder for ${reminder.title}`} src={Close} onClick={() => handleDelete()} />
			</ReminderDeleteContainer>
		</StyledUpcomingFavoriteBox>
	)
}