import React, { useEffect, useState } from 'react';
import { BlueButton } from '../../Global/GobalStyledComponents';
import {
	SettingsContainer, SettingsHeader, SettingsOptionsContainer, Understanding, SettingsPulldown,
	SettingsLabel, CheckboxContainer, SaveButtonContainer, ReturnToGrid, SaveMessage, DataError, SettingsLabelSpacer
} from '../components/SettingsStyles';
import TabBar from '../components/TabBar';
import { CheckBox } from '../../Global/GobalStyledComponents';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
	setStartTime, setFontSize, setHideNGChannels, setFixedCellHeight, setDisplayChannelLogo, setDisplayShowCards,
	selectAudioDescription, selectCastBio, selectCellDescription, selectEpsTitle, selectNewRepeat, selectTvRating,
	selectDefaultLineup, selectDetailsShowCard, selectDetailsSynopsis, selectDisplayChannelLogo, selectDisplayShowCards,
	selectFixedCellHeight, selectFontSize, selectGenreDisplay, selectHDIndicator, selectHideNGChannels, selectMovieRating,
	selectNextGenIndicator, selectPostalCode, selectProgramType, selectSeasonNumber, selectSocialMediaLinks,
	selectWrapCellText, selectYearProduction, postUserSettings, selectStartTime, selectDailyGridWidth, selectGridColorScheme,
	selectEnableWatch, selectEnableRecord, selectEnableBurnToDVD, selectEnableRemoteScheduling, selectPVRChannel,
	setSettingMainGridDuration, selectSettingMainGridDuration, selectCompactMode, setCompactMode
} from '../SettingsState/gridSettingsSlice';
import {
	selectMainGridDuration, setMainGridDuration, selectChannelSort, setChannelSort, fetchSchedule, selectGridLineupID, setGridTime
} from '../../GridPages/mainGridSlice';
import { selectUserId } from '../SettingsState/userSlice';
import GirdColorSelect from '../../GridPages/components/GirdColorSelect';
import { PostUserSettings } from '../../../interfaces/settings';
import { GetDefaultUser, getScreenSizeType, leadingZeroBuilder } from '../../Global/GobalFunctions';
import { ScreenSize, SortType } from '../../../interfaces/enums';
import BecomeMemberText from '../components/BecomeMemberText';

const GridSettings = () => {
	const dispatch = useAppDispatch();
	const startTime = useAppSelector(selectStartTime);
	const mainGridDuration = useAppSelector(selectMainGridDuration);
	const lineupOrder = useAppSelector(selectChannelSort);
	const fontSize = useAppSelector(selectFontSize);
	const hideNG = useAppSelector(selectHideNGChannels);
	const fixedCellHeight = useAppSelector(selectFixedCellHeight);
	const displayChannelLogo = useAppSelector(selectDisplayChannelLogo);
	const displayShowCards = useAppSelector(selectDisplayShowCards);
	const userId = useAppSelector(selectUserId);
	const lineupId = useAppSelector(selectGridLineupID);
	const settingsMainGridDuration = useAppSelector(selectSettingMainGridDuration)
	const compactMode = useAppSelector(selectCompactMode);

	const [displayGridSaveMessage, setDisplayGridSaveMessage] = useState(false);
	const postSettings: PostUserSettings = {
		postalCode: useAppSelector(selectPostalCode),
		defaultLineupId: useAppSelector(selectDefaultLineup),
		userId: userId,
		gridSettings: {
			gridStartHour: parseInt(useAppSelector(selectStartTime)),
			gridDuration: useAppSelector(selectSettingMainGridDuration),
			channelSortOrder: useAppSelector(selectChannelSort),
			gridFontSize: useAppSelector(selectFontSize),
			hideNextGen: useAppSelector(selectHideNGChannels),
			wrapCellText: useAppSelector(selectWrapCellText),
			fixedCellHeight: useAppSelector(selectFixedCellHeight),
			includeLogos: useAppSelector(selectDisplayChannelLogo),
			includeShowCards: useAppSelector(selectDisplayShowCards),
			gridDailyWidth: useAppSelector(selectDailyGridWidth),
			gridColorScheme: useAppSelector(selectGridColorScheme),
			compactMode: compactMode
		},
		cellSettings: {
			includeDescription: useAppSelector(selectCellDescription),
			includeEpisodeTitle: useAppSelector(selectEpsTitle),
			includeSeasonNumbers: useAppSelector(selectSeasonNumber),
			includeHDIndicator: useAppSelector(selectHDIndicator),
			includeNewRepeatIndicator: useAppSelector(selectNewRepeat),
			includeGenres: useAppSelector(selectGenreDisplay),
			includeMovieYear: useAppSelector(selectYearProduction),
			includeMovieRatings: useAppSelector(selectMovieRating),
			includeTVRatings: useAppSelector(selectTvRating),
			includeOriginalAirDate: true,
			includeProgramType: useAppSelector(selectProgramType),
			includeNextGenIndicator: useAppSelector(selectNextGenIndicator),
			includeAudioStreams: useAppSelector(selectAudioDescription),
			includeSocialMediaLinks: useAppSelector(selectSocialMediaLinks),
		},
		detailSettings: {
			includeSeriesDescription: useAppSelector(selectDetailsSynopsis),
			includeShowCards: useAppSelector(selectDetailsShowCard),
			includeCastAndCrewLinks: useAppSelector(selectCastBio)
		},
		pvrSettings: {
			enableWatch: useAppSelector(selectEnableWatch),
			enableRecord: useAppSelector(selectEnableRecord),
			enableRemoteScheduling: useAppSelector(selectEnableRemoteScheduling),
			enableBurnToDVD: useAppSelector(selectEnableBurnToDVD),
			channel: useAppSelector(selectPVRChannel)
		}
	}

	const handleSaveSettings = () => {
		if (userId === GetDefaultUser()) {
			setDisplayGridSaveMessage(true);
			return;
		}
		setDisplayGridSaveMessage(true);
		dispatch(postUserSettings(postSettings))
	}

	const handleStartTimeUpdate = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const date = new Date();

		dispatch(setStartTime(e.target.value));
		if (e.target.value !== '-1') {
			date.setHours(parseInt(e.target.value));
		}
		dispatch(setGridTime(date.getHours()));
		const startTimeToken = `${date.getFullYear()}${leadingZeroBuilder(date.getMonth())}${leadingZeroBuilder(date.getDate())}${leadingZeroBuilder(date.getHours())}00`;
		dispatch(fetchSchedule({ userId: userId, lineupId: lineupId, startTimeToken: startTimeToken, duration: mainGridDuration }));
	}

	const handleGridDurationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		switch (getScreenSizeType()) {
			case ScreenSize.MOBILE:
				dispatch(setMainGridDuration(1));
				break;
			case ScreenSize.SMALL:
				if (parseInt(e.target.value) > 2) {
					dispatch(setMainGridDuration(2));
				} else {
					dispatch(setMainGridDuration(parseInt(e.target.value)));
				}
				break;
			case ScreenSize.MEDIUM:
			default:
				if (parseInt(e.target.value) > 3) {
					dispatch(setMainGridDuration(3));
				} else {
					dispatch(setMainGridDuration(parseInt(e.target.value)));
				}
				break;
		}
		dispatch(setMainGridDuration(e.target.value));
		dispatch(setSettingMainGridDuration(parseInt(e.target.value)))
	}

	const shouldDisplayOption = (size: number) => {
		switch (getScreenSizeType()) {
			case ScreenSize.MOBILE:
				if (size === 1) {
					return true
				} else {
					return false;
				}
			case ScreenSize.SMALL:
				if (size < 3) {
					return true
				} else {
					return false;
				}
			default:
				return true

		}
	}

	useEffect(() => {
		window.scrollTo(0, 0);
		return () => {
			setDisplayGridSaveMessage(false);
		}
	}, [])

	return (
		<>
			<TabBar />
			<SettingsContainer>
				<SettingsOptionsContainer>
					<SettingsHeader>Grid Starting Time</SettingsHeader>

					<SettingsPulldown id='gridStartTime' defaultValue={startTime} onChange={(e) => handleStartTimeUpdate(e)}>
						<option value="-1">Current Time</option>
						<option value="19">Prime Time</option>
						<option value="0">12 AM</option>
						<option value="1">1 AM</option>
						<option value="2">2 AM</option>
						<option value="3">3 AM</option>
						<option value="4">4 AM</option>
						<option value="5">5 AM</option>
						<option value="6">6 AM</option>
						<option value="7">7 AM</option>
						<option value="8">8 AM</option>
						<option value="9">9 AM</option>
						<option value="10">10 AM</option>
						<option value="11">11 AM</option>
						<option value="12">12 PM</option>
						<option value="13">1 PM</option>
						<option value="14">2 PM</option>
						<option value="15">3 PM</option>
						<option value="16">4 PM</option>
						<option value="17">5 PM</option>
						<option value="18">6 PM</option>
						<option value="19">7 PM</option>
						<option value="20">8 PM</option>
						<option value="21">9 PM</option>
						<option value="22">10 PM</option>
						<option value="23">11 PM</option>
					</SettingsPulldown>
					<SettingsLabel htmlFor='gridStartTime'>Specifies the default starting time of the schedule grid when it opens</SettingsLabel>

					<SettingsHeader>Grid Duration</SettingsHeader>

					<SettingsPulldown id='gridDuration' value={settingsMainGridDuration + ''} onChange={(e) => handleGridDurationChange(e)}>
						{shouldDisplayOption(1) ? <option value="1">1 Hours</option> : ''}
						{shouldDisplayOption(2) ? <option value="2">2 Hours</option> : ''}
						{shouldDisplayOption(3) ? <option value="3">3 Hours</option> : ''}
						{shouldDisplayOption(4) ? <option value="4">4 Hours</option> : ''}
						{shouldDisplayOption(5) ? <option value="5">5 Hours</option> : ''}
						{shouldDisplayOption(6) ? <option value="6">6 Hours</option> : ''}
					</SettingsPulldown>
					<SettingsLabel htmlFor='gridDuration'>
						Specifies the number of hours of programming to be displayed in the grid.
						<SettingsLabelSpacer />
						(Space constraints may reduce the number of hours displayed on small screens.)
					</SettingsLabel>

					<SettingsHeader>Channel Sort Order</SettingsHeader>
					<SettingsPulldown id='lineup' value={lineupOrder} onChange={(e) => dispatch(setChannelSort(e.target.value))}>
						<option value={SortType.LINEUP}>Lineup Order</option>
						<option value={SortType.CALLSIGN}>Callsign</option>
						<option value={SortType.CALLSIGN_DESC}>Callsign Descending</option>
						<option value={SortType.CHANNEL_NUMBER}>Channel Number</option>
						<option value={SortType.CHANNEL_NUMBER_DESC}>Channel Number Descending</option>
					</SettingsPulldown>
					<SettingsLabel htmlFor='lineup'>
						Specifies the default order the channels in each lineup are displayed in the grid.
					</SettingsLabel>

					<SettingsHeader>Grid Font Size</SettingsHeader>
					<SettingsPulldown id='fontSize' defaultValue={fontSize} onChange={(e) => dispatch(setFontSize(e.target.value))}>
						<option value=".75">Small</option>
						<option value="1">Normal</option>
						<option value="1.25">Large</option>
						<option value="1.5">Extra Large</option>
					</SettingsPulldown>
					<SettingsLabel htmlFor='fontSize'>
						Specifies the size of the text displayed in the grid
					</SettingsLabel>

					<SettingsHeader>Compact Mode</SettingsHeader>
					<CheckboxContainer>
						<CheckBox id='compactMode' defaultChecked={compactMode} onChange={() => dispatch(setCompactMode())} />
						<SettingsLabel htmlFor='compactMode'>Reduces the cell height to display more channels in a single view</SettingsLabel>
					</CheckboxContainer>

					<SettingsHeader>Hide NG Channels</SettingsHeader>
					<CheckboxContainer>
						<CheckBox id='nextGenCbx' defaultChecked={hideNG} onChange={() => dispatch(setHideNGChannels())} />
						<SettingsLabel htmlFor='nextGenCbx'>Suppress the display of NEXTGEN TV channels</SettingsLabel>
					</CheckboxContainer>

					<SettingsHeader>Use Fixed Height Cells</SettingsHeader>
					<CheckboxContainer>
						<CheckBox id='fixedHeightCbx' defaultChecked={fixedCellHeight} onChange={() => dispatch(setFixedCellHeight())} />
						<SettingsLabel htmlFor='fixedHeightCbx'>Make all of the cells in the grid a uniform height</SettingsLabel>
					</CheckboxContainer>

					<SettingsHeader>Display Channel Logos</SettingsHeader>
					<CheckboxContainer>
						<CheckBox id='channelLogos' defaultChecked={displayChannelLogo} onChange={() => dispatch(setDisplayChannelLogo())} />
						<SettingsLabel htmlFor='channelLogos'>Display station or network affiliaction logos</SettingsLabel>
					</CheckboxContainer>

					<SettingsHeader>Display Show Cards</SettingsHeader>
					<CheckboxContainer>
						<CheckBox id='showCards' defaultChecked={displayShowCards} onChange={() => dispatch(setDisplayShowCards())} />
						<SettingsLabel htmlFor='showCards'>Include a graphics title card for each program in the schedule</SettingsLabel>
					</CheckboxContainer>

					<SettingsHeader>Grid Color Scheme</SettingsHeader>
					<GirdColorSelect />

					<SaveButtonContainer>
						<BlueButton onClick={() => handleSaveSettings()}>Save Changes</BlueButton>
						<SaveMessage show={displayGridSaveMessage}>
							{userId === GetDefaultUser() ?
								<>
									<DataError>You must create a user to update settings</DataError>
								</>
								: 'Your Settings Have Been Saved'}
							{userId === GetDefaultUser() ?
								<BecomeMemberText />
								:
								<ReturnToGrid to='/'>
									Return to TV Listing
								</ReturnToGrid>}
						</SaveMessage>
					</SaveButtonContainer>

				</SettingsOptionsContainer>
				<Understanding>
					<span>
						<b>About This Page:</b>
						<p>This page contains the settings that determine the look of the main programming grid page.</p>
						<p>Grid Starting Time: Determines the grid's default starting hour and time span.</p>
						<p>Channel Sort Order: Controls the display order of the channels in each lineup.</p>
						<p>Grid Font Size: Establishes the size of the text displayed in the grid.</p>
						<p>Compact Mode: Limits the grid to display only Program Title, Episode Title, and New/Repeat status.</p>
						<p>Hide NG Channels: Enables the display of NextGen TV (-NG) channels.</p>
						<p>Use Fixed Height Cells: Controls the height of the grid cells. This setting displays all cells at a uniform height or allows the height of each cell to expand according to the amount of details.</p>
						<p>Display Channel Logos: Toggles the display of a station's/network's logo or its call sign/network name.</p>
						<p>Display Show Cards: Allows each program's show card to be displayed in its cell.</p>
						<p>Grid Color Scheme: Selects the grid genre coloring scheme.</p>
						<p>Click the Save Changes button to put the grid settings into effect.</p>
					</span>
				</Understanding>
			</SettingsContainer>
		</>
	)
}

export default GridSettings